/* eslint-disable react/prop-types */
/* eslint-disable react/no-danger */
/* eslint-disable import/prefer-default-export */

// https://www.gatsbyjs.org/docs/browser-apis/

// import node_modules
import AOS from 'aos';
import React from 'react';
import { Helmet } from 'react-helmet';
import { createGlobalStyle } from 'styled-components';

// import styles
import colors from './src/styles/colors';
import { defaultTextStyle, mainHeadlineStyle, linkStyle } from './src/styles/fonts';

// import styles
import 'aos/dist/aos.css';

// define global styles
const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    margin: 0;
    width: 100%;
  }

  * ::selection {
    background-color: ${colors.light};
    color: ${colors.black};
  }

  .is-text {
    ${defaultTextStyle}

    .container {
      margin: 2rem auto 5rem;
      max-width: 720px;
      padding: 1rem;
    }


    h1, h2, h3, h4, h5, h6 {
      ${mainHeadlineStyle}
    }

    h1 {
      font-size: 48px;
    }

    h2 {
      font-size: 36px;
    }

    h3 {
      font-size: 28px;
    }

    h4 {
      font-size: 24px;
    }

    h5 {
      font-size: 20px;
    }

    h6 {
      font-size: 18px;
    }

    a {
      ${linkStyle}
    }
  }
`;

// wrap root element with styles
export const wrapRootElement = ({ element }) => (
  <>
    <Helmet>
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdn.jsdelivr.net/npm/cookieconsent@3/build/cookieconsent.min.css"
      />
      <script
        src="https://cdn.jsdelivr.net/npm/cookieconsent@3/build/cookieconsent.min.js"
        data-cfasync="false"
      />
    </Helmet>

    <GlobalStyle />
    {element}
  </>
);

// wrap page element with translation and init AOS
export const wrapPageElement = ({ element }) => {
  // init AOS for fade animation
  if (typeof window !== 'undefined') {
    AOS.init({
      // Global settings:
      animatedClassName: 'aos-animate', // class applied on animation
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      initClassName: 'aos-init', // class applied after initialization
      startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll

      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 400, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      mirror: false, // whether elements should animate out while scrolling past them
      offset: 120, // offset (in px) from the original trigger point
      once: true, // whether animation should happen only once - while scrolling down
    });
  }

  // render app
  return <>{element}</>;
};
