// COLORS
const rawColors = {
  codGrey: '#1b1b1b',
  deepBlush: '#dd709a',
  iron: '#E6E7E8',
  tePapaGreen: '#1b403d',
  white: '#ffffff',
  lightGreen: 'rgba(71, 172, 167, 0.8)',
};

const colors = {
  black: rawColors.codGrey,
  dark: rawColors.tePapaGreen,
  light: rawColors.iron,
  link: rawColors.deepBlush,
  primary: rawColors.deepBlush,
  secondary: rawColors.tePapaGreen,
  transparent: rawColors.lightGreen,
  white: rawColors.white,
};

export default colors;
