// import node_modules
import { css } from 'styled-components';

// import styles
import colors from './colors';
import transitions from './transitions';

// define fonts
const fonts = {
  families: {
    default: 'Rajdhani, sans-serif',
    headlines: 'Rajdhani, serif',
    monospace: 'Rajdhani, monospace',
    links: 'Rajdhani, serif',
  },
  sizes: {
    extraLargeMobile: '28px',
    extraLarge: '36px',
    extraSmallMobile: '10px',
    extraSmall: '11px',
    largeMobile: '18px',
    large: '20px',
    normalMobile: '14px',
    normal: '16px',
    smallMobile: '12px',
    small: '13px',
    inputFields: '16px', // NOTE: this is because of focus on mobiles
  },
  weights: {
    regular: 400,
    bold: 700,
  },
  letterSpacings: {
    buttons: '0.5px',
    default: '0.2px',
    headlines: '0.8px',
    labels: '0.5px',
  },
  lineHeights: {
    buttons: 1.3,
    default: 1.5,
    headlines: 1.3,
    labels: 1.3,
  },
};

export const mainHeadlineStyle = css`
  color: ${colors.black};
  font-family: ${fonts.families.headlines};
  font-weight: ${fonts.weights.bold};
  letter-spacing: ${fonts.letterSpacings.headlines};
  line-height: ${fonts.lineHeights.headlines};
  text-transform: uppercase;
`;

export const headlineStyle = css`
  color: ${colors.black};
  font-family: ${fonts.families.default};
  font-weight: ${fonts.weights.bold};
  letter-spacing: ${fonts.letterSpacings.headlines};
  line-height: ${fonts.lineHeights.headlines};
  text-transform: uppercase;
`;

export const labelStyle = css`
  color: ${colors.dark};
  font-family: ${fonts.families.default};
  font-weight: ${fonts.weights.bold};
  letter-spacing: ${fonts.letterSpacings.labels};
  line-height: ${fonts.lineHeights.labels};
`;

export const defaultTextStyle = css`
  color: ${colors.dark};
  font-family: ${fonts.families.default};
  font-weight: ${fonts.weights.regular};
  letter-spacing: ${fonts.letterSpacings.default};
  line-height: ${fonts.lineHeights.default};
`;

export const linkStyle = css`
  border-bottom-color: ${colors.dark};
  border-bottom-style: solid;
  border-bottom-width: 1px;
  color: ${colors.dark};
  cursor: pointer;
  font-family: ${fonts.families.default};
  font-weight: ${fonts.weights.regular};
  letter-spacing: ${fonts.letterSpacings.default};
  line-height: ${fonts.lineHeights.default};
  text-decoration: none;
  transition: all ${transitions.easeOut};

  &:focus,
  &:hover {
    border-color: ${colors.link};
    color: ${colors.link};
  }
`;

export default fonts;
