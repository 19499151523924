module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#ffffff","description":"I focus on business development and digital innovation in the Private Markets investment space.","dir":"ltr","display":"standalone","icon":"./static/apple-icon.png","name":"Silke Blume","orientation":"portrait-primary","short_name":"Silke Blume","start_url":"/","theme_color":"#1D403D","icons":[{"src":"/favicon.png","sizes":"512x512","type":"image/png"},{"src":"/android-icon-36x36.png","sizes":"36x36","type":"image/png","density":"0.75"},{"src":"/android-icon-48x48.png","sizes":"48x48","type":"image/png","density":"1.0"},{"src":"/android-icon-72x72.png","sizes":"72x72","type":"image/png","density":"1.5"},{"src":"/android-icon-96x96.png","sizes":"96x96","type":"image/png","density":"2.0"},{"src":"/android-icon-144x144.png","sizes":"144x144","type":"image/png","density":"3.0"},{"src":"/android-icon-192x192.png","sizes":"192x192","type":"image/png","density":"4.0"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"bf426f4dc31931dee3e2d6c0e06bdbb8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#1D403D"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
